import {getDictionary, getSafeDictionaryLanguage} from '@questovery/dictionary';
import {LoaderFunctionArgs, MetaFunction, json, redirect, type LinksFunction} from '@remix-run/node';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useMatches,
  useRouteError,
} from '@remix-run/react';
import {captureRemixErrorBoundaryError, withSentry} from '@sentry/remix';
import {Crisp} from 'crisp-sdk-web';
import {useEffect} from 'react';
import {sessionService} from './core/session.server';
import tailwind from './tailwind.css?url';
import {Toaster} from './ui/sonner';
import {ViewProvider} from './ui/view';

export const links: LinksFunction = () => {
  return [
    {rel: 'stylesheet', href: tailwind},
    {rel: 'icon', href: '/favicon.ico'},
  ];
};

export const loader = async (args: LoaderFunctionArgs) => {
  const domain = args.request.headers.get('host') || '';
  if (domain === 'questovery.com') {
    const urlWithoutHost = args.request.url.split('/').slice(3);
    throw redirect(`https://www.questovery.com/${urlWithoutHost.join('/')}`, {status: 301});
  }
  const lang = args.request.headers.get('Accept-Language')?.split(',')[0]?.split('-')[0] || 'en';
  const consent = (await sessionService.consent.get(args.request.headers)) || undefined;
  return json({lang, user: null, consent});
};

export const meta: MetaFunction<typeof loader> = ({data}) => {
  const t = getDictionary(getSafeDictionaryLanguage(data?.lang));
  return [
    {title: t.head_title},
    {name: 'description', content: t.head_description},
    {name: 'viewport', content: 'width=device-width,initial-scale=1'},
    {charset: 'utf-8'},
    {name: 'apple-itunes-app', content: 'app-id=6458731191'},
  ];
};

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <div>Something went wrong</div>;
};

const App = () => {
  const {lang, consent} = useLoaderData<typeof loader>();

  useCrispChat();
  useUmami();

  return (
    <html lang={lang}>
      <head>
        <Links />
        <Meta />
      </head>
      <body className="min-h-screen flex flex-col transition-colors duration-500 ease-in-out">
        <ViewProvider>
          <Outlet />
        </ViewProvider>
        <ScrollRestoration />
        <Scripts />
        <Toaster richColors />
      </body>
    </html>
  );
};

export default withSentry(App);

export const useRootData = () => {
  const matches = useMatches();
  const rootMatches = matches.filter(match => match.id === 'root')[0];
  return rootMatches.data as Awaited<ReturnType<typeof loader>>;
};

export type RootData = ReturnType<typeof useRootData>;

const useCrispChat = () => {
  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      Crisp.setSafeMode(true);
      Crisp.configure('eef1e66c-efa2-4ac8-a1a6-52d2f6bcc05d');
    }
  }, []);
};

const useUmami = () => {
  useEffect(() => {
    if (typeof window !== 'undefined' && process.env.NODE_ENV === 'production') {
      const script = document.createElement('script');
      script.src = 'https://umami.lezo.dev/script.js';
      script.setAttribute('data-website-id', 'ac161cf5-ff54-487c-a6c1-ca973099c63d');
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);
};
